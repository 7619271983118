import React from 'react';
import styled from 'styled-components';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

import { rhythm, scale } from 'utils/typography';
import { BaseSection } from './Base';

const Section = styled(BaseSection)`
  /* color: #999; */
  position: relative;

  & > div {
    /* border-top: 1px solid rgba(0, 0, 0, 0.25); */
    &:: before {
      content: '';
      display: block;
      width: 60%;
      height: 1px;
      background-color: rgba(0, 0, 0, 0.25);
      position: absolute;
      top: 0;
      left: 20%;
    }
  }
`;

const SectionTitle = styled.h2`
  color: ${props => props.theme.color.heading};
  ${scale(3 / 2)};
  font-weight: 400;
  margin: 0 0 ${rhythm(1)};
  text-align: center;
`;

export default () => (
  <Section>
    <div>
      <SectionTitle>References</SectionTitle>
      <ol>
        <li id="endnote1">
          Bautista, N. U. (2011). Investigating the Use of Vicarious and Mastery
          Experiences in Influencing Early Childhood Education Majors’
          Self-Efficacy Beliefs. <i>Journal of Science Teacher Education</i>,
          22(4), 333-349.{' '}
          <OutboundLink
            href="http://dx.doi.org/10.1007/s10972-011-9232-5"
            rel="noopener noreferrer"
            target="_blank"
          >
            doi:10.1007/s10972-011-9232-5
          </OutboundLink>
        </li>
        <li id="endnote2">
          Fayyaz, N. (2018, September 29). Why role models are important.{' '}
          <i>Dawn</i>. Retrieved from{' '}
          <OutboundLink
            href="https://www.dawn.com/news/1435581"
            rel="noopener noreferrer"
            target="_blank"
          >
            https:// www.dawn.com/news/1435581
          </OutboundLink>
        </li>
        <li id="endnote3">
          Whitbourne, S. K., Ph.D. (2013, November 19). We All Need Role Models
          to Motivate and Inspire Us. <i>Psychology Today</i>. Retrieved from{' '}
          <OutboundLink
            href="https://www.psychologytoday.com/ca/blog/fulfillment-any-age/201311/we-all-need-role-models-motivate-and-inspire-us"
            rel="noopener noreferrer"
            target="_blank"
          >
            https://
            www.psychologytoday.com/ca/blog/fulfillment-any-age/201311/we-all-need-role-models-motivate-and-inspire-us
          </OutboundLink>
        </li>
        <li id="endnote4">
          Ranson, A. (2016, March 14). The importance of rhyme in early literacy
          development. Retrieved from{' '}
          <OutboundLink
            href="https://theimaginationtree.com/the-importance-of-rhyme-in-early-literacy-development/"
            rel="noopener noreferrer"
            target="_blank"
          >
            https://
            theimaginationtree.com/the-importance-of-rhyme-in-early-literacy-development/
          </OutboundLink>
        </li>
        <li id="endnote5">
          Pre-Reading Skills - How to introduce pre-reading to your child.
          (2017, September 20). Retrieved from{' '}
          <OutboundLink
            href="https://www.thelearningtree.ca/blog/child-education/pre-reading-skills/"
            rel="noopener noreferrer"
            target="_blank"
          >
            https://
            www.thelearningtree.ca/blog/child-education/pre-reading-skills/
          </OutboundLink>
        </li>
        <li id="endnote6">
          Andsager, J. L., Bemker, V., Choi, H., &amp; Torwel, V. (2006).
          Perceived Similarity of Exemplar Traits and Behavior: Effects on
          Message Evaluation. <i>Communication Research</i>, 33(1), 3-18.{' '}
          <OutboundLink
            href="http://dx.doi.org/10.1177/0093650205283099"
            rel="noopener noreferrer"
            target="_blank"
          >
            doi:10.1177/0093650205283099
          </OutboundLink>
        </li>
        <li id="endnote7">
          Ito, K. E., Kalyanaraman, S., Brown, J. D., &amp; Miller, W. C.
          (2008). 10: Factors Affecting Avatar Use in a STI Prevention CD-ROM.{' '}
          <i>Journal of Adolescent Health</i>, 42(2), 19.{' '}
          <OutboundLink
            href="http://dx.doi.org/10.1016/j.jadohealth.2007.11.052"
            rel="noopener noreferrer"
            target="_blank"
          >
            doi:10.1016/j.jadohealth.2007.11.052
          </OutboundLink>
        </li>
        <li id="endnote8">
          Meichenbaum, D. H. (1971). Examination of model characteristics in
          reducing avoidance behavior.{' '}
          <i>Journal of Personality and Social Psychology</i>, 17(3), 298-307.{' '}
          <OutboundLink
            href="http://dx.doi.org/10.1037/h0030593"
            rel="noopener noreferrer"
            target="_blank"
          >
            doi:10.1037/h0030593
          </OutboundLink>
        </li>
        <li id="endnote9">
          Rosen, P. (n.d.). Flexible Thinking: What You Need to Know. Retrieved
          from{' '}
          <OutboundLink
            href="https://www.understood.org/en/learning-attention-issues/child-learning-disabilities/executive-functioning-issues/flexible-thinking-what-you-need-to-know"
            rel="noopener noreferrer"
            target="_blank"
          >
            https://
            www.understood.org/en/learning-attention-issues/child-learning-disabilities/executive-functioning-issues/flexible-thinking-what-you-need-to-know
          </OutboundLink>
        </li>
        <li id="endnote10">
          Rawlings, L. (n.d.). What is Initiative and Why is It Important?
          Retrieved from{' '}
          <OutboundLink
            href="https://www.youthemployment.org.uk/yp-article/what-is-initiative-and-why-is-it-important/"
            rel="noopener noreferrer"
            target="_blank"
          >
            https://
            www.youthemployment.org.uk/yp-article/what-is-initiative-and-why-is-it-important/
          </OutboundLink>
        </li>
        <li id="endnote11">
          By. (n.d.). 9 Benefits of Taking Initiative. Retrieved from{' '}
          <OutboundLink
            href="https://lizjansen.com/initiative/"
            rel="noopener noreferrer"
            target="_blank"
          >
            https:// lizjansen.com/initiative/
          </OutboundLink>
        </li>
        <li id="endnote12">
          How to stop negative self-talk. (2017, February 18). Retrieved from{' '}
          <OutboundLink
            href="https://www.mayoclinic.org/healthy-lifestyle/stress-management/in-depth/positive-thinking/art-20043950"
            rel="noopener noreferrer"
            target="_blank"
          >
            https://
            www.mayoclinic.org/healthy-lifestyle/stress-management/in-depth/positive-thinking/art-20043950
          </OutboundLink>
        </li>
        <li id="endnote13">
          Riordan, C. M. (2014, August 07). We All Need Friends at Work.
          Retrieved from{' '}
          <OutboundLink
            href="https://hbr.org/2013/07/we-all-need-friends-at-work"
            rel="noopener noreferrer"
            target="_blank"
          >
            https:// hbr.org/2013/07/we-all-need-friends-at-work
          </OutboundLink>
        </li>
        <li id="endnote14">
          Children and the benefits of friendship. (n.d.). Retrieved from{' '}
          <OutboundLink
            href="https://www.lifeeducation.org.au/parents/children-and-the-benefits-of-friendship"
            rel="noopener noreferrer"
            target="_blank"
          >
            https://
            www.lifeeducation.org.au/parents/children-and-the-benefits-of-friendship
          </OutboundLink>
        </li>
        <li id="endnote15">
          Barish, K., Ph.D. (2012, December 21). Nurturing a Spirit of Caring
          and Generosity in Children. Retrieved from{' '}
          <OutboundLink
            href="https://www.psychologytoday.com/ca/blog/pride-and-joy/201212/nurturing-spirit-caring-and-generosity-in-children"
            rel="noopener noreferrer"
            target="_blank"
          >
            https://
            www.psychologytoday.com/ca/blog/pride-and-joy/201212/nurturing-spirit-caring-and-generosity-in-children
          </OutboundLink>
        </li>
        <li id="endnote16">
          Ioannidou, F., &amp; Konstantikaki, V. (2008, Fall).{' '}
          <OutboundLink
            href="http://internationaljournalofcaringsciences.org/docs/Vol1_Issue3_03_Ioannidou.pdf"
            rel="noopener noreferrer"
            target="_blank"
          >
            Empathy and emotional intelligence: What is it really about?
          </OutboundLink>{' '}
          <i>International Journal of Caring Sciences</i>, 1(3), 118-123.
        </li>
        <li id="endnote17">
          Empathy. (n.d.). Retrieved from{' '}
          <OutboundLink
            href="https://www.berkeleywellbeing.com/empathy.html"
            rel="noopener noreferrer"
            target="_blank"
          >
            https:// www.berkeleywellbeing.com/empathy.html
          </OutboundLink>
        </li>
        <li id="endnote18">
          Markway, B., Ph.D. (2018, September 20). Why Self-Confidence Is More
          Important Than You Think. Retrieved from{' '}
          <OutboundLink
            href="https://www.psychologytoday.com/ca/blog/shyness-is-nice/201809/why-self-confidence-is-more-important-you-think"
            rel="noopener noreferrer"
            target="_blank"
          >
            https://
            www.psychologytoday.com/ca/blog/shyness-is-nice/201809/why-self-confidence-is-more-important-you-think
          </OutboundLink>
        </li>
        <li id="endnote19">
          Line, R. (n.d.). Five Reasons Good Sportsmanship Matters. Retrieved
          from{' '}
          <OutboundLink
            href="http://theseason.gc.com/coach-advice-five-reasons-good-sportsmanship-matters"
            rel="noopener noreferrer"
            target="_blank"
          >
            http://
            theseason.gc.com/coach-advice-five-reasons-good-sportsmanship-matters
          </OutboundLink>
        </li>
        <li id="endnote20">
          Ditkowsky, A. (2018, October 13). For Families: Tips for Encouraging
          Honesty. Retrieved from{' '}
          <OutboundLink
            href="https://mcc.gse.harvard.edu/resources-for-families/tips-encouraging-honesty"
            rel="noopener noreferrer"
            target="_blank"
          >
            https://
            mcc.gse.harvard.edu/resources-for-families/tips-encouraging-honesty
          </OutboundLink>
        </li>
        <li id="endnote21">
          Teaching Responsibility to Children. (n.d.). Retrieved from{' '}
          <OutboundLink
            href="https://centerforparentingeducation.org/library-of-articles/responsibility-and-chores/developing-responsibility-in-your-children/"
            rel="noopener noreferrer"
            target="_blank"
          >
            https://
            centerforparentingeducation.org/library-of-articles/responsibility-and-chores/developing-responsibility-in-your-children/
          </OutboundLink>
        </li>
      </ol>
    </div>
  </Section>
);
