import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import { Layout, SEO } from 'components';
import { rhythm, scale } from 'utils/typography';

import {
  About,
  AboutTheAuthor,
  AnnouncementBanner,
  ChallengesAndLessons,
  Characters,
  Hero,
  References,
  TheScience,
} from 'components/Home/sections';

export const BaseSection = styled.section`
  background-color: #fff;
  margin: 0 auto;
`;

const Main = styled.main`
  /* & h2 {
    color: ${props => props.theme.color.heading};
    font-size: 5.75rem;
    ${scale(2.5)};
    font-weight: 400;
  } */

  /* & > section {
    & > div {
      padding: ${rhythm(2)} 0;
      max-width: 64em;
      margin: 0 auto;
    }
  } */
`;

export default function Home() {
  return (
    <Layout>
      <SEO
        title="Pragmatic Princess - Your Own Castle"
        image="/group-picture.jpg"
        imageAlt="Illustration of various Pragmatic Princess characters producing a stage production in their school's theater"
      />
      <Main>
        <AnnouncementBanner />
        <Hero />
        <About />
        <TheScience />
        <ChallengesAndLessons />
        <Characters />
        <AboutTheAuthor />
        <References />
      </Main>
    </Layout>
  );
}
