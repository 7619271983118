import React from 'react';
import styled from 'styled-components';

import { rhythm, scale } from 'utils/typography';
import { BaseSection } from './Base';

const Section = styled(BaseSection)`
  /* color: #999; */
  padding-top: ${rhythm(1)};
`;

const SectionTitle = styled.h2`
  color: ${props => props.theme.color.heading};
  text-align: center;

  @media (min-width: 48em) {
    margin-bottom: ${rhythm(3)};
  }
`;

const SectionH3 = styled.h3`
  color: ${props => props.theme.color.heading};
  ${scale(1.25)};
`;

export default () => (
  <Section id="about_the_author">
    <div>
      <SectionTitle>Meet the Creators</SectionTitle>
      <SectionH3>Author</SectionH3>
      <p>
        Dr. Rachel Kowert is a research psychologist originally from Austin,
        Texas and currently residing in Ottawa, Ontario. She is a mom of two
        children and a pug named Cid. She has previously published several
        non-fiction books.
      </p>

      <SectionH3>Illustrator</SectionH3>
      <p>
        Randall Hampton is an author, illustrator, husband and father of 3. He
        is the creator of "The Little Game Master" series of books and considers
        himself a story teller above all else. When he is not spending time with
        family and friends, Randall enjoys reading, video games, music, and
        table top games of all sorts.
      </p>
    </div>
  </Section>
);
