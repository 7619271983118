import React from 'react';
import styled from 'styled-components';
import Scroll from 'react-scroll';

import paperBackgroundImage from 'images/bg-science.jpg';
import { ReactComponent as BooksIcon } from 'images/Icon-Books.svg';
import { ReactComponent as MusicIcon } from 'images/Icon-Music.svg';
import { ReactComponent as DiversityIcon } from 'images/Icon-Diversity.svg';
import { rhythm, scale } from 'utils/typography';
import { BaseSection } from './Base';

const Section = styled(BaseSection)`
  background-color: #37369e;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  text-align: center;
  padding: ${rhythm(1.5)} ${rhythm(2)} ${rhythm(1)};

  @media (min-width: 48em) {
    background-color: #fff;
    background-image: url(${paperBackgroundImage});
    padding: ${rhythm(5)} ${rhythm(2)} ${rhythm(3)};
  }
`;

const SectionContent = styled.div`
  margin: 0 auto;
  max-width: 64rem;
`;

const SectionTitle = styled.h2`
  @media (min-width: 48em) {
    margin-bottom: ${rhythm(4)};
  }
`;

const SectionSummary = styled.p`
  font-weight: 300;
  margin: ${rhythm(1)} 0;
  ${scale(1 / 3)};
  line-height: 1.75rem;

  @media (min-width: 48em) {
    margin: ${rhythm(3)} 0;
    ${scale(1 / 2)};
  }
`;

const ThreeColumns = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${rhythm(1)};

  @media (min-width: 48em) {
    rid-gap: ${rhythm(2)};
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const ColumnHeader = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-bottom: ${rhythm(1 / 2)};

  @media (min-width: 48em) {
    flex-flow: column nowrap;
    margin-bottom: ${rhythm(1)};
  }
`;

const ColumnHeading = styled.h3`
  margin: 0 0 0 ${rhythm(1 / 2)};
  ${scale(1.25)};

  @media (min-width: 48em) {
    margin-left: 0;
  }
`;

const ColumnIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  width: 3rem;

  @media (min-width: 48em) {
    width: 8rem;
    height: 8rem;
    margin: 0 auto ${rhythm(1)};
  }

  & svg {
    width: 100%;
    height: auto;
    max-height: 3rem;

    @media (min-width: 48em) {
      max-height: 8rem;
    }
  }
`;

const JustifiedParagraph = styled.p`
  text-align: left;

  & > sup > a {
    color: #fff;

    &:visited {
      color: #fff;
    }

    &:focus,
    &:hover {
      color: yellow;
    }
  }
`;

export default () => (
  <Section id="science">
    <SectionContent>
      <SectionTitle>The science behind the writing</SectionTitle>
      <ThreeColumns>
        <div>
          <ColumnHeader>
            <ColumnIcon>
              <BooksIcon />
            </ColumnIcon>
            <ColumnHeading>Story Length</ColumnHeading>
          </ColumnHeader>
          <JustifiedParagraph>
            A general rule of thumb is children’s picture books should be
            between 500 and 1000 words. This length is short enough to hold the
            attention of the younger pre-readers but long enough to tell a fully
            fleshed out story. With this in mind, each story in the{' '}
            <i>Pragmatic Princess</i> was crafted to be between 450 and 650
            words. This length also allows for multiple stories in the
            compilation to be read in a single session for the older readers.
          </JustifiedParagraph>
        </div>
        <div>
          <ColumnHeader>
            <ColumnIcon>
              <MusicIcon />
            </ColumnIcon>
            <ColumnHeading>Rhyming Scheme</ColumnHeading>
          </ColumnHeader>
          <JustifiedParagraph>
            Books that rhyme are fun to read because of their natural, song-like
            rhythm. They can also make the content easier to memorize for
            younger children, which can help transition them in to the
            pre-reading stage of development. This is an important milestone as
            it helps build confidence for reading and interest to further engage
            with reading. Rhyming is also an important part of reading success
            and it has also been found to encourage the development of writing
            skills.
            <sup>
              <Scroll.Link to="endnote4" smooth duration={500}>
                4
              </Scroll.Link>
              ,{' '}
              <Scroll.Link to="endnote5" smooth duration={500}>
                5
              </Scroll.Link>
            </sup>
          </JustifiedParagraph>
        </div>
        <div>
          <ColumnHeader>
            <ColumnIcon>
              <DiversityIcon />
            </ColumnIcon>
            <ColumnHeading>Diverse Characters</ColumnHeading>
          </ColumnHeader>
          <JustifiedParagraph>
            It was important that the characters of <i>Pragmatic Princess</i>{' '}
            were diverse: all shapes and colors, many different backgrounds,
            with various abilities and disabilities, and from traditional and
            nontraditional families. It is important for literature to reflect
            our daily lives so readers can relate to the characters. The diverse
            cast also maximizes the learning opportunities within each story, as
            people are more likely to emulate role models of the same sex,
            ethnicity, and same skill level of any particular activity.
            <sup>
              <Scroll.Link to="endnote6" smooth duration={500}>
                6
              </Scroll.Link>
              ,{' '}
              <Scroll.Link to="endnote7" smooth duration={500}>
                7
              </Scroll.Link>
              ,{' '}
              <Scroll.Link to="endnote8" smooth duration={500}>
                8
              </Scroll.Link>
            </sup>
          </JustifiedParagraph>
        </div>
      </ThreeColumns>
    </SectionContent>
  </Section>
);
