import React, { useState } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';

import { rhythm, scale } from 'utils/typography';
import CarouselDots from '../CarouselDots';

const quotes = [
  // {
  //   author: 'Tabitha the Talkative',
  //   key: 'tabitha',
  //   text: "“No one likes to feel like they are not heard,\nand you really chat away undeterred.\nI think everyone would be less peeved,\nAnd you would likely be better received,\nif you started listening to others,\nyour constant talking makes us feel smothered.”\n\nTabitha was upset, but told Bella she'd try,\n“I don't listen to my friends!?” she felt mortified.", // Replace newlines with <br /> and double newlines with </p><>
  // },
  {
    author: 'Quinn the Quick',
    key: 'quinn',
    text: "So she decided to no longer stress,\nand to never again try to be less.\nWhat other people thought, did not matter,\nit was just frustration, idle chatter.",
  },
  {
    author: 'Eliana the Energetic',
    key: 'eliana',
    text: "“Focus on the good in others and everyday,\nand don't ever let anyone take your joy away.”",
  },
  {
    author: 'Christina the Curious',
    key: 'christina',
    text: "“Asking questions and seeing for yourself,“ she smirked,\n“is the only way to know how and why things work.“",
  },
  {
    author: 'Xena the Xenial',
    key: 'xena',
    text: "“I think that everyone is special in their own way,\nand has something to offer each and every day.\nI think remembering that makes us all more kind,\nand hospitable to every person we find.”",
  },
  {
    author: 'Ruby the Ready',
    key: 'ruby',
    text: "“The next time you feel curious about something,\ngo to the person directly without judging.\nYou'll learn so much more by talking to each other,\nthan from hearsay or gossip with one another.”",
  },
  {
    author: 'Lina the Leader',
    key: 'lina',
    text: "“When we learn about other people and places,\nforeign traditions and customs, other races,\nwe begin to foster our sense of charity,\nand set roots for the cornerstones of empathy.”",
  },
  {
    author: 'Zoe the Zealous',
    key: 'zoe',
    text: "Zoe went on, “If we never try something new,\nwe'll never know how much that we can really do.”",
  },
  {
    author: 'Paige the Protector',
    key: 'paige',
    text: "“Remember the importance of following through,\nbecause keeping your word is the right thing to do.\nBe sure to always show up when you say you'll come,\nso your responsibilities don't go undone.”",
  },
  {
    author: 'Valerie the Victorious',
    key: 'valerie',
    text: "She didn't let a fear of failing make her frown,\nand insisted, “Failures should never slow us down,\nthey are simply a first attempt to teach us how,\nto be better and stronger, right here and right now.”",
  },
];

const CarouselContainer = styled.div``;

const Slide = styled.div`
  display: grid;
  grid-template-columns: minmax(10rem, 32rem) 4rem;
  grid-gap: 0;

  @media (min-width: 48em) {
    grid-template-columns: minmax(10rem, 32rem) 15rem;
    grid-gap: ${rhythm(2)};
    margin: ${rhythm(2)};
  }

  & img {
    height: 100%;
    width: auto;
  }
`;

const Quote = styled.blockquote`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  border: 0;
  font-family: 'Amatic SC', sans-serif;
  font-weight: 400;
  margin: 0;
  padding: 0;
  font-size: 0.75rem;
  line-height: 1.5rem;

  @media (min-width: 48em) {
    ${scale(7 / 8)};
  }

  & cite {
    color: #fff;
    display: inline-block;
    font-weight: 700;
    margin: ${rhythm(1)} 0 0;
    ${scale(0)};

    @media (min-width: 48em) {
      ${scale(8 / 8)};
    }
  }
`;

const ImageContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
`;

const CarouselArrowLeft = styled.span`
  display: block;
  border-style: solid;
  border-color: #fff;
  border-width: 5px 5px 0 0;
  opacity: 0.5;
  padding: 10px;
  transition: 0.3s;
  font-size: 0;
  transform: translate(-50%, -50%) rotate(-135deg);
  margin-left: calc(0.35 * 15px);
  position: relative;
  left: 14px;

  &:hover {
    opacity: 1;
  }
`;

const CarouselArrowRight = styled(CarouselArrowLeft)`
  transform: translate(-50%, -50%) rotate(45deg);
  margin-left: calc(-0.35 * 15px);
  position: relative;
  left: 7px;
`;

export default () => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  return (
    <StaticQuery
      query={graphql`
        query {
          christinaImage: file(
            relativePath: { eq: "pragmatic_princess/christina.png" }
          ) {
            childImageSharp {
              fluid(maxHeight: 218, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
                presentationWidth
              }
            }
          }
          elianaImage: file(
            relativePath: { eq: "pragmatic_princess/eliana.png" }
          ) {
            childImageSharp {
              fluid(maxHeight: 218, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
                presentationWidth
              }
            }
          }
          linaImage: file(relativePath: { eq: "pragmatic_princess/lina.png" }) {
            childImageSharp {
              fluid(maxHeight: 218, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
                presentationWidth
              }
            }
          }
          paigeImage: file(
            relativePath: { eq: "pragmatic_princess/paige.png" }
          ) {
            childImageSharp {
              fluid(maxHeight: 218, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
                presentationWidth
              }
            }
          }
          quinnImage: file(
            relativePath: { eq: "pragmatic_princess/quinn.png" }
          ) {
            childImageSharp {
              fluid(maxHeight: 218, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
                presentationWidth
              }
            }
          }
          rubyImage: file(relativePath: { eq: "pragmatic_princess/ruby.png" }) {
            childImageSharp {
              fluid(maxHeight: 218, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
                presentationWidth
              }
            }
          }
          tabithaImage: file(
            relativePath: { eq: "pragmatic_princess/tabitha.png" }
          ) {
            childImageSharp {
              fluid(maxHeight: 218, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
                presentationWidth
              }
            }
          }
          valerieImage: file(
            relativePath: { eq: "pragmatic_princess/valerie.png" }
          ) {
            childImageSharp {
              fluid(maxHeight: 218, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
                presentationWidth
              }
            }
          }
          xenaImage: file(relativePath: { eq: "pragmatic_princess/xena.png" }) {
            childImageSharp {
              fluid(maxHeight: 218, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
                presentationWidth
              }
            }
          }
          zoeImage: file(relativePath: { eq: "pragmatic_princess/zoe.png" }) {
            childImageSharp {
              fluid(maxHeight: 218, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
                presentationWidth
              }
            }
          }
        }
      `}
      render={data => (
        <CarouselContainer>
          <Carousel
            arrowLeft={<CarouselArrowLeft />}
            arrowRight={<CarouselArrowRight />}
            addArrowClickHandler
            draggable
            slidesPerPage={1}
            slidesPerScroll={1}
            stopAutoPlayOnHover
            centered
            infinite
            animationSpeed={1500}
            autoPlay={7000}
            value={currentSlideIndex}
            onChange={value => setCurrentSlideIndex(value)}
          >
            {quotes.map(quote => {
              const image = data[`${quote.key}Image`].childImageSharp;
              return (
                <Slide key={quote.key}>
                  <Quote>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: `<p>${quote.text
                          .replace(/\n\n/g, '</p><p>')
                          .replace(/\n/g, '<br />')}</p>`,
                      }}
                    />
                    <cite>– {quote.author}</cite>
                  </Quote>
                  <ImageContainer>
                    <Img
                      fluid={image.fluid}
                      alt={`Drawing of ${quote.author}`}
                      loading="eager"
                      style={{
                        maxHeight: '218px',
                        width: image.fluid.presentationWidth,
                      }}
                    />
                  </ImageContainer>
                </Slide>
              );
            })}
          </Carousel>
          <CarouselDots
            value={currentSlideIndex}
            onChange={value => setCurrentSlideIndex(value)}
            number={quotes.length}
          />
        </CarouselContainer>
      )}
    />
  );
};
