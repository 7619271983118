import React, { useState } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';

import paperBackgroundImage from 'images/bg-science.jpg';
import { rhythm, scale } from 'utils/typography';
import CharactersCarousel from './CharactersCarousel';
import { BaseSection } from './Base';

const Section = styled(BaseSection)`
  background-color: #37369e;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  text-align: center;
  padding: ${rhythm(1.5)} ${rhythm(2)} ${rhythm(1)};

  @media (min-width: 48em) {
    background-color: #fff;
    background-image: url(${paperBackgroundImage});
    padding: ${rhythm(5)} ${rhythm(2)} ${rhythm(3)};
  }
`;

const SectionContent = styled.div`
  margin: 0 auto;
  max-width: 64rem;
`;

const SectionTitle = styled.h2`
  color: #fff;

  @media (min-width: 48em) {
    margin-bottom: ${rhythm(3)};
  }
`;

const AboutTheCharacters = styled.div`
  margin: ${rhythm(2)} 0;
  text-align: left;

  @media (min-width: 48em) {
    margin: ${rhythm(2)};
  }
`;

const ClassImageContainer = styled.div`
  margin: ${rhythm(1 / 2)} 0;

  @media (min-width: 48em) {
    margin: ${rhythm(1)};
  }
`;

export default () => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  return (
    <StaticQuery
      query={graphql`
        query {
          charactersImage: file(relativePath: { eq: "characters.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 1024, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
                presentationWidth
              }
            }
          }
        }
      `}
      render={data => {
        return (
          <Section id="characters">
            <SectionContent>
              <SectionTitle>Meet the Characters!</SectionTitle>
              <CharactersCarousel />
              <AboutTheCharacters>
                <p>
                  The characters of <i>Pragmatic Princess</i> were developed to
                  represent a range of shapes, sizes, abilities and
                  disabilities, and traditional and non-traditional families.
                </p>
                <p>
                  For example, Eliana relies on a wheelchair to get around.
                  Ursula uses her hands to communicate. Isabel lives with her
                  grandmother. Ophelia is being raised by a single father,
                  Tabitha by a single mother. Harriet and Jasmine come from
                  mixed-race families. Yuna lives with her adoptive parents and
                  siblings.
                </p>
                <p>
                  It was important to incorporate diverse backgrounds into the
                  characters' lives for them to reflect the diverse world we
                  live in. It was equally important that these details were not
                  the central theme of the stories themselves as our shape,
                  level of ability, and what our family unit looks like are just
                  some of the many parts of who we are and not necessarily the
                  defining feature of our stories.
                </p>
              </AboutTheCharacters>
              <ClassImageContainer>
                <Img
                  fluid={data.charactersImage.childImageSharp.fluid}
                  alt="Pragmatic Princess Characters"
                  style={{
                    maxWidth: '1024px',
                  }}
                />
              </ClassImageContainer>
            </SectionContent>
          </Section>
        );
      }}
    />
  );
};
