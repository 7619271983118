import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';

import { rhythm, scale } from 'utils/typography';

const CarouselContainer = styled.div``;

const Slide = styled.div`
  width: 100%;

  & img {
    height: 100%;
    width: auto;
  }
`;

export default () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          groupPhoto: file(
            relativePath: { eq: "pragmatic_princess/group-photo.jpg" }
          ) {
            childImageSharp {
              fluid(maxWidth: 1024, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
                presentationHeight
              }
            }
          }
          bookCover: file(
            relativePath: { eq: "pragmatic_princess/book-cover.jpg" }
          ) {
            childImageSharp {
              fluid(maxWidth: 1024, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
                presentationHeight
              }
            }
          }
          samplePages: file(
            relativePath: { eq: "pragmatic_princess/sample-pages.png" }
          ) {
            childImageSharp {
              fluid(maxWidth: 1024, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
                presentationHeight
              }
            }
          }
        }
      `}
      render={data => (
        <CarouselContainer>
          <Carousel
            draggable
            slidesPerPage={1}
            slidesPerScroll={1}
            stopAutoPlayOnHover
            centered
            infinite
            animationSpeed={1500}
            autoPlay={5000}
          >
            <Slide>
              <Img
                fluid={data.groupPhoto.childImageSharp.fluid}
                alt="Illustration of various Pragmatic Princess characters producing a stage production in their school's theater"
                loading="eager"
                style={{
                  maxWidth: '1024px',
                  height: data.groupPhoto.childImageSharp.presentationHeight,
                }}
              />
            </Slide>
            <Slide>
              <Img
                fluid={data.bookCover.childImageSharp.fluid}
                alt="Illustration of Pragmatic Princess book cover"
                loading="eager"
                style={{
                  maxWidth: '1024px',
                  height: data.groupPhoto.childImageSharp.presentationHeight,
                }}
              />
            </Slide>
            <Slide>
              <Img
                fluid={data.samplePages.childImageSharp.fluid}
                alt="Illustration of pages from Pragmatic Princess"
                loading="eager"
                style={{
                  maxWidth: '1024px',
                  height: data.groupPhoto.childImageSharp.presentationHeight,
                }}
              />
            </Slide>
          </Carousel>
        </CarouselContainer>
      )}
    />
  );
};
