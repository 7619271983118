import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import Scroll from 'react-scroll';
import styled from 'styled-components';

import { rhythm, scale } from 'utils/typography';
import { ScrollView } from 'react-native';
import { BaseSection } from './Base';
import AboutCarousel from './AboutCarousel';

const Section = styled(BaseSection)`
  text-align: left;
  padding: ${rhythm(1)} ${rhythm(2)};

  & h2 {
    color: ${props => props.theme.color.heading};
    ${scale(2.5)};
    font-weight: 400;
    text-align: center;
  }

  & > div {
    padding-bottom: 0;
  }
`;

const ProjectSummary = styled.p`
  color: #5d56c1;
  ${scale(1 / 3)};
  line-height: 1.5;
  font-weight: 300;
  margin-top: ${rhythm(2)};
  font-feature-settings: 'lnum';
`;

export default () => {
  const data = useStaticQuery(graphql`
    query {
      groupImage: file(relativePath: { eq: "group-picture.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1024, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            presentationWidth
          }
        }
      }
    }
  `);

  return (
    <Section id="about">
      <div>
        <AboutCarousel />
        {/* <Img
          fluid={data.groupImage.childImageSharp.fluid}
          alt="Illustration of various Pragmatic Princess characters producing a stage production in their school's theater"
          style={{
            maxWidth: '1024px',
          }}
        /> */}
        <ProjectSummary>
          <i>Pragmatic Princess</i> is an A-Z compilation of short stories about
          self-reliance designed for children age 3-8+.
        </ProjectSummary>
        <p>
          The fictional characters in childhood stories are some of our earliest
          teachers. We learn a range of things through the observation of these
          symbolic models, such as what is right and wrong, a desirable and
          undesirable behavior, gender roles, norms, stereotypes, and more. The
          role of models is particularly influential in childhood as it can have
          a long lasting impact on intellectual, social, emotional and moral
          development.
          <sup>
            <Scroll.Link to="endnote1" smooth duration={500}>
              1
            </Scroll.Link>
            ,{' '}
            <Scroll.Link to="endnote2" smooth duration={500}>
              2
            </Scroll.Link>
            ,{' '}
            <Scroll.Link to="endnote3" smooth duration={500}>
              3
            </Scroll.Link>
          </sup>{' '}
          This is why it is critical to have stories that move beyond the belief
          that female characters are best suited as damsels in distress or need
          superpowers to be successful.
        </p>
        <p>
          <i>Pragmatic Princess</i> not only changes the narrative to one of
          self-reliance, but does so with the power of science behind it. I drew
          heavily on my experience as a research psychologist to create stories
          that are fun and entertaining while also maximizing learning
          opportunities. This was done by carefully choosing the story length
          and structure, creating narratives that reflect the the challenges and
          lessons of childhood, and developing a diverse cast of characters.
        </p>
      </div>
    </Section>
  );
};
