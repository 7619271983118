import React from 'react';
import styled from 'styled-components';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

import { ScreenReadersOnly, SocialMediaIcons } from 'components';
import heroBackgroundImage from 'images/hero.jpg';
import { ReactComponent as LogoTextImage } from 'images/logos/logo-text.svg';
import { useScript } from 'utils/hooks';
import { rhythm, scale } from 'utils/typography';
import { BaseSection } from './Base';

const Section = styled(BaseSection)`
  align-items: center;
  background-color: #37369e;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  display: flex;
  flex-flow: column nowrap;
  padding: ${rhythm(1)} ${rhythm(2)};

  @media (min-width: 48em) {
    background-color: #fff;
    background-image: url(${heroBackgroundImage});
    margin: 0;
    padding: ${rhythm(1)} ${rhythm(2)} ${rhythm(5)};
  }

  /* @media (min-width: 90em) {
    min-height: 746px;
    padding: ${rhythm(3)} ${rhythm(2)};
  } */

  & h1 {
    min-width: 20rem;
    max-width: 36rem;
    width: 50%;
    margin-bottom: 0;
  }

  & h2 {
    color: #fff;
    font-family: 'Amatic SC', sans-serif;
    /* font-size: 2.5rem; */
    ${scale(4 / 4)};
    font-weight: 400;
    border-bottom: none;
    position: relative;
    margin: ${rhythm(1 / 2)} 0 ${rhythm(2)};
    text-align: center;

    @media (min-width: 48em) {
      margin: ${rhythm(2)} 0 ${rhythm(4)};
      ${scale(5 / 4)};
    }

    &::after {
      content: '';
      width: 80%;
      height: 2px;
      background-color: #fff;
      position: absolute;
      bottom: -${rhythm(1)};
      left: 10%;

      @media (min-width: 48em) {
        bottom: -${rhythm(2)};
      }
    }
  }

  & > div {
    margin: 0;
    padding: 0;
    text-align: center;

    @media (min-width: 48em) {
      margin-bottom: ${rhythm(1)};
    }
  }
`;

const StyledLogoTextImage = styled(LogoTextImage)`
  & .cls-1,
  & .cls-2,
  & .cls-3,
  & .cls-4 {
    fill: #fff;
  }
`;

const KickStarterButton = styled(OutboundLink)`
  font-family: 'Amatic SC', sans-serif;
  ${scale(1)};
  color: #fff;
  cursor: pointer;
  display: inline-block;
  background-color: #754af1;
  margin: 0 0 ${rhythm(1)};
  border-radius: 1rem;
  padding: ${rhythm(0.75)} ${rhythm(1)};
  box-shadow: 0 0.5rem 0.5rem -0.5rem rgba(0, 0, 0, 0.8),
    inset 0 0 1rem 0.25rem rgba(0, 0, 0, 0.25);
  transition: background-color 0.3s ease-in-out;

  &:hover,
  &:focus {
    background-color: #8765e6;
    color: #fff;
  }

  &:visited {
    color: #fff;
  }
`;

export default () => {
  return (
    <Section>
      <h1>
        <StyledLogoTextImage />
        <ScreenReadersOnly>Pragmatic Princess</ScreenReadersOnly>
      </h1>
      <h2>26 Superb Stories of Self-Sufficiency</h2>
      <div>
        <p>
          We have launched a second KickStarter campaign for a new series of
          books! Click the button below to check it out!
        </p>
        <KickStarterButton
          href="https://www.kickstarter.com/projects/pragmaticprincess/tales-from-cloud-canyon?ref=project_build"
          rel="noopener noreferrer"
          target="_blank"
        >
          Go to KickStarter
        </KickStarterButton>
        <SocialMediaIcons />
      </div>
    </Section>
  );
};
