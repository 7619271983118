import React from 'react';
import styled from 'styled-components';

import { ReactComponent as KickstarterLogoImage } from 'images/logo-kickstarter.svg';
import { rhythm, scale } from 'utils/typography';

const Section = styled.div`
  background: #27118e;
  color: #fff;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: ${rhythm(1 / 2)} ${rhythm(2 / 3)};
  font-family: 'Amatic SC', sans-serif;
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0);
  ${scale(4 / 6)};

  @media (min-width: 48em) {
    padding: ${rhythm(5 / 6)} ${rhythm(1)};
    ${scale(6 / 6)};
  }

  & svg {
    margin: 4px 0 0 -2px;
    width: 180px;
    max-height: 32px;

    & .cls-5 {
      fill: white;
    }

    @media (min-width: 48em) {
      width: 220px;
      margin-left: ${rhythm(1 / 4)};
    }
  }
`;

const ComingSoon = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
  justify-content: center;
  text-align: center;

  @media (min-width: 48em) {
    flex-flow: row nowrap;
    justify-content: left;
    text-align: left;
  }
`;

export default () => (
  <Section>
    <ComingSoon>
      {/* <span>
        Now available from{' '}
        <a href="https://www.amazon.com/Pragmatic-Princess-Superb-Stories-Self-Suffiency/dp/0578583690/">
          Amazon.com
        </a>{' '}
        and{' '}
        <a href="https://www.amazon.ca/Pragmatic-Princess-Superb-Stories-Self-Suffiency/dp/0578583690/">
          Amazon.ca
        </a>
        !
      </span> */}
      <span>
        Check out the latest addition to the Cloud Canyon universe. Funding now
        on{' '}
      </span>
      <a
        href="https://www.kickstarter.com/projects/pragmaticprincess/tales-from-cloud-canyon?ref=project_build"
        rel="noopener noreferrer"
        target="_blank"
      >
        <KickstarterLogoImage alt="Kickstarter" />
      </a>
    </ComingSoon>
  </Section>
);
