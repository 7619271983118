import React from 'react';
import Scroll from 'react-scroll';
import styled from 'styled-components';

import { ReactComponent as BrainIcon } from 'images/Icon-Brain.svg';
import { ReactComponent as ChatIcon } from 'images/Icon-Chat.svg';
import { ReactComponent as CheckmarkIcon } from 'images/Icon-Checkmark.svg';
import { ReactComponent as HeartIcon } from 'images/Icon-Heart.svg';
import { rhythm, scale } from 'utils/typography';
import { BaseSection } from './Base';

const Section = styled(BaseSection)`
  /* color: #999; */
  text-align: center;
  padding-top: ${rhythm(1)};
`;

const SectionTitle = styled.h2`
  color: ${props => props.theme.color.heading};
  ${scale(2.5)};
  font-weight: 400;
  margin-bottom: ${rhythm(1.5)};

  @media (min-width: 48em) {
    margin-bottom: ${rhythm(3)};
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${rhythm(1)};

  @media (min-width: 48em) {
    grid-template-columns: 1fr 1fr;
    grid-gap: ${rhythm(2)};
  }
`;

const ColumnHeader = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-bottom: ${rhythm(1 / 2)};

  @media (min-width: 48em) {
    flex-flow: column nowrap;
    margin-bottom: ${rhythm(1)};
  }
`;

const ColumnHeading = styled.h3`
  color: ${props => props.theme.color.heading};
  margin: 0 0 0 ${rhythm(1 / 2)};
  ${scale(1.25)};

  @media (min-width: 48em) {
    margin-left: 0;
  }
`;

const ColumnIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  width: 3rem;

  @media (min-width: 48em) {
    height: 8rem;
    width: 8rem;
    margin: 0 auto ${rhythm(1)};
  }

  & svg {
    width: 100%;
    height: auto;
    max-height: 3rem;

    @media (min-width: 48em) {
      max-height: 8rem;
    }
  }
`;

const Column = styled.div`
  & p,
  dl {
    text-align: left;
  }

  & dt {
    font-weight: 700;
  }
`;

const Standout = styled.p`
  color: #5d56c1;
  ${scale(1 / 3)};
  line-height: 1.5;
  font-weight: 300;
  margin: ${rhythm(1)} 0;
`;

export default () => (
  <Section id="challenges_and_lessons">
    <Column>
      <SectionTitle>Challenges &amp; Lessons</SectionTitle>
      <Standout>
        The characters and stories within <i>Pragmatic Princess</i> were
        developed to be symbolic models for a range of behaviors that span four
        areas of human development.
      </Standout>
      <p>
        Within each story in <i>Pragmatic Princess</i>, the main character in is
        confronted with a typical childhood challenge (e.g., a fear of trying
        something new or schoolyard bullying) and models a wide range of skills
        and ideas to navigate these challenges, such as problem solving,
        flexible thinking, and resourcefulness. The use of real-world situations
        and a diverse cast help the readers relate to the characters and
        experiences, which is critical to fostering observational learning and
        the successful aquisition of the skills modeled in each of the stories.
      </p>
      <Grid>
        <Column>
          <ColumnHeader>
            <ColumnIcon>
              <BrainIcon />
            </ColumnIcon>
            <ColumnHeading>Intellectual</ColumnHeading>
          </ColumnHeader>
          <p>
            Intellectual development refers to the language learning, reasoning
            skills, and problem-solving abilities. The specific areas of
            intellectual development addressed by the characters and stories
            within <i>Pragmatic Princess</i> include:
          </p>
          <dl>
            <dt>Cognitive flexibility</dt>
            <dd>
              A skill that allows us to think about something in more than one
              way. Cognitive flexibility is an executive skill key essential for
              problem solving.
              <sup>
                <Scroll.Link to="endnote9" smooth duration={500}>
                  9
                </Scroll.Link>
              </sup>
            </dd>
            <dt>Initiative taking</dt>
            <dd>
              Taking charge of a situation and the willingness to get things
              done and take responsibility. Taking initiative is a
              self-management skill, and one of five key life and work skills
              for young adults. There various benefits associated with taking
              initiative, including confidence, self-awareness, and creativity.
              <sup>
                <Scroll.Link to="endnote10" smooth duration={500}>
                  10
                </Scroll.Link>
                ,{' '}
                <Scroll.Link to="endnote11" smooth duration={500}>
                  11
                </Scroll.Link>
              </sup>
            </dd>
            <dt>Positive thinking</dt>
            <dd>
              Being able to approach unpleasantness in a more positive way. The
              Mayo Clinic found that positive thinking and positive self-talk
              have a range of benefits, including lower levels of stress,
              greater physical immunity, better psychological well-being, and
              better coping skills during hardships and times of stress.
              <sup>
                <Scroll.Link to="endnote12" smooth duration={500}>
                  12
                </Scroll.Link>
              </sup>
            </dd>
          </dl>
        </Column>
        <Column>
          <ColumnHeader>
            <ColumnIcon>
              <ChatIcon />
            </ColumnIcon>
            <ColumnHeading>Social</ColumnHeading>
          </ColumnHeader>
          <p>
            Social development is the process of gaining the knowledge and
            skills needed to interact successfully with others. The specific
            areas of social development addressed by the characters and stories
            within <i>Pragmatic Princess</i> include:
          </p>
          <dl>
            <dt>Camaraderie</dt>
            <dd>
              Mutual trust and friendship among people who spend a lot of time
              together. Camaraderie is a vital element of friendship and
              essential for social and emotional development.
              <sup>
                <Scroll.Link to="endnote13" smooth duration={500}>
                  13
                </Scroll.Link>
                ,{' '}
                <Scroll.Link to="endnote14" smooth duration={500}>
                  14
                </Scroll.Link>
              </sup>
            </dd>
            <dt>Encouragement</dt>
            <dd>
              The action of giving someone support, confidence, or hope.
              Encouragement is a critical skill for leadership.
            </dd>
            <dt>Generosity</dt>
            <dd>
              The act of being generous. Research has found that extending
              generosity can lead to improved self-esteem, a better immune
              system, and even a longer life span.
              <sup>
                <Scroll.Link to="endnote15" smooth duration={500}>
                  15
                </Scroll.Link>
              </sup>
            </dd>
            <dt>Perspective taking</dt>
            <dd>
              The act of perceiving a situation or understanding a concept from
              an alternative point of view is an essential social skill. We need
              to be able to understand how others see the world in order to
              effectively work together and show empathy and understanding.
            </dd>
          </dl>
        </Column>
        <Column>
          <ColumnHeader>
            <ColumnIcon>
              <HeartIcon />
            </ColumnIcon>
            <ColumnHeading>Emotional</ColumnHeading>
          </ColumnHeader>
          <p>
            Emotional development refers to changes in understanding feelings
            and appropriate forms of expressing them. The specific areas of
            emotional development addressed by the characters and stories within
            <i>Pragmatic Princess</i> include:
          </p>
          <dl>
            <dt>Empathy</dt>
            <dd>
              The ability to understand and share the feelings of another.
              Empathy is the cornerstone of emotional intelligence. Empathy has
              been linked to stronger, more meaningful relationships,
              occupational success, and higher quality of life.
              <sup>
                <Scroll.Link to="endnote16" smooth duration={500}>
                  16
                </Scroll.Link>
              </sup>
            </dd>
            <dt>Gratitude</dt>
            <dd>
              The quality of being thankful; readiness to show appreciation for
              and to return kindness. Gratitude is an important skill for
              fostering empathy.
              <sup>
                <Scroll.Link to="endnote17" smooth duration={500}>
                  17
                </Scroll.Link>
              </sup>
            </dd>
            <dt>Self-confidence</dt>
            <dd>
              A feeling of trust in one’s abilities, qualities, and judgement.
              Self-confidence is linked to almost every element involved in a
              happy life.
              <sup>
                <Scroll.Link to="endnote18" smooth duration={500}>
                  18
                </Scroll.Link>
              </sup>
            </dd>
          </dl>
        </Column>
        <Column>
          <ColumnHeader>
            <ColumnIcon>
              <CheckmarkIcon />
            </ColumnIcon>
            <ColumnHeading>Moral</ColumnHeading>
          </ColumnHeader>
          <p>
            Moral development is the growing understanding of what is right and
            wrong, and the change in behavior caused by that understanding,
            sometimes called conscience. The specific areas of intellectual
            development addressed by the characters and stories within
            <i>Pragmatic Princess</i> are:
          </p>
          <dl>
            <dt>Sportsmanship</dt>
            <dd>
              Fair and generous behavior or treatment of others. Good
              sportsmanship teaches teamwork, respect for others, and emphasizes
              the roles of fair play.
              <sup>
                <Scroll.Link to="endnote19" smooth duration={500}>
                  19
                </Scroll.Link>
              </sup>
            </dd>
            <dt>Honesty</dt>
            <dd>
              The quality of being honest. This crucial life skill is learned in
              childhood, often through modeling.
              <sup>
                <Scroll.Link to="endnote20" smooth duration={500}>
                  20
                </Scroll.Link>
              </sup>
            </dd>
            <dt>Responsibility</dt>
            <dd>
              The state or fact of being accountable for something. The
              opportunity or ability to act independently and make decisions
              without authorization. Being responsible is key to children's
              success in school.
              <sup>
                <Scroll.Link to="endnote21" smooth duration={500}>
                  21
                </Scroll.Link>
              </sup>
            </dd>
          </dl>
        </Column>
      </Grid>
    </Column>
  </Section>
);
