import styled from 'styled-components';
import { rhythm, scale } from 'utils/typography';

export const BaseSection = styled.section`
  background-color: #fff;
  margin: 0 auto;
  padding: 0 ${rhythm(2)};

  @media (min-width: 48em) {
    padding-left: ${rhythm(2)};
    padding-right: ${rhythm(2)};
  }

  & > div {
    padding: ${rhythm(1 / 2)} 0;
    max-width: 64em;
    margin: 0 auto;

    @media (min-width: 48em) {
      padding: ${rhythm(2)} 0;
    }

    & > h2 {
      font-weight: 400;
      ${scale(13 / 8)};

      @media (min-width: 48em) {
        ${scale(5 / 2)};
      }
    }
  }
`;
