import React, { useCallback } from 'react';
import styled from 'styled-components';
// import '../styles/CarouselDots.scss';

import { ScreenReadersOnly } from 'components';

interface Props {
  number: number;
  value: number;
  onChange(newValue: number): void;
}

const Container = styled.ul`
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 0;
  line-height: 0;

  & li {
    margin: 0;
    padding: 0;
  }
`;

const Button = styled.button`
  background: #fff;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  height: 10px;
  width: 10px;
  margin: 0 0.25rem;
  padding: 0;
  opacity: ${props => (props.selected ? 1 : 0.5)};
  outline: 0;
  transition: opacity 0.3s ease-in-out;
  -webkit-appearance: none;

  &:hover {
    opacity: 1;
  }
`;

export default (props: Props) => {
  const numberOfSlides = props.number;
  const buttonValue =
    props.value >= 0
      ? props.value
      : props.value +
        numberOfSlides * Math.ceil(Math.abs(props.value / numberOfSlides));
  const moduloItem = buttonValue % numberOfSlides;

  const onChange = useCallback(
    (index: number) => () => props.onChange(props.value - (moduloItem - index)),
    [props.onChange]
  );
  // const onChange = (index: number) => () =>
  //   props.onChange(props.value - (moduloItem - index));

  // const renderCarouselDots = () => {
  //   const dots = [];
  //   for (let i = 0; i < props.number; i++) {
  //     const selected = i === buttonValue % props.number;
  //     dots.push(
  //       <li key={i}>
  //         <Button selected={selected} onClick={onChange(i)}>
  //           <ScreenReadersOnly>Slide {i + 1}</ScreenReadersOnly>
  //         </Button>
  //       </li>
  //     );
  //   }

  //   return dots;
  // };

  return (
    <Container>
      {Array(props.number)
        .fill(1)
        .map((_, index) => (
          <li key={index}>
            <Button
              selected={index === buttonValue % props.number}
              onClick={onChange(index)}
            >
              <ScreenReadersOnly>Slide {index + 1}</ScreenReadersOnly>
            </Button>
          </li>
        ))}
    </Container>
  );
};
